.Navside {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.Contents {
    padding: 1rem 1rem 1rem 0;
}

.NavsideItem {
    padding: 0.75rem 1rem;
}

.NavsideIcon {
    width: 2rem;
    margin-right: 1rem;
    text-align: center;
    display: inline-block;
}

.Open {
    transform: translateX(0);
}

.Close {
    transform: translateX(-100%);
}