.btn-primary {
    background-color: var(--wnw2Primary);
    border-color: var(--wnw2Primary);
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus {
    background-color: rgba(var(--wnw2PrimaryRgb), 0.9);
    border-color: var(--wnw2Primary);
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    background-color: rgba(var(--wnw2PrimaryRgb), 0.9);
    border-color: var(--wnw2Primary);
}

.btn-outline-primary {
    color: var(--wnw2Primary);
    border-color: var(--wnw2Primary);
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus {
    background-color: rgba(var(--wnw2PrimaryRgb), 0.9);
    border-color: var(--wnw2Primary);
    color: #FFFFFF;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    background-color: rgba(var(--wnw2PrimaryRgb), 0.9);
    border-color: var(--wnw2Primary);
}

.badge-primary {
    background-color: var(--wnw2Primary);
    border-color: var(--wnw2Primary);
}

h1 {
    font-weight: normal;
    font-size: 2em;
    line-height: 1.5;
}

h2 {
    font-weight: normal;
    font-size: 1.5em;
    line-height: 1.5;
}

h3 {
    font-weight: normal;
    font-size: 1.17em;
    line-height: 1.5;
}