html {
    font-size: 15px;
}

body {
    /* background-color: rgba(100, 255, 218, 1); */
    background-color: #EEEEEE;
    margin: 0;
    font-family: 'Noto Sans TC', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select, textarea, button {
    font-family: inherit;
    font-size: inherit;
}

:root {
    --wnw2Primary: #10191E;
    --wnw2PrimaryRgb: 16, 25, 30;
    --wnwLightBlue: #40A4C8;
    --wnwOrange: #FFC107;
    --wnwLightOrange: #FFCC80;
    --wnwGreen: #28a745;
    --wnwLightGreen: #D4EDDA;
    --wnwRed: #944317;
    --wnwLightRed: #FF726F;
}

/* Chrome, Safari, Edge, Opera */
/* This removes the up-down buttons in input boxes */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.floating-label input {
    font-family: 'Noto Sans TC', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
}

.floating-label + .floating-label {
    margin-top: 0 !important;
}



.notification {
    padding: 15px !important;
}
.notification-container {
    top: 65px !important;
}

.notification-message {
    text-align: center;
}

.notification-success {
    background-color: rgba(0, 0, 0, 0.8) !important;
    /* background-color: var(--wnw2Primary) !important; */
}

.notification-error:before, .notification-success:before, .notification-warning, .notification-info {
    content: none !important;
}



.validation-error {
    color: red;
    margin: 0 0 0 5px;
    text-align: left;
}

.pointer:hover {
    cursor: pointer;
}

/* This is used in ProductVar */
.editing-row {
    background-color: var(--wnwLightBlue) !important;
}

.Breadcrumb {
    margin: 5px 20px;
    box-sizing: border-box;
    font-size: 1.2rem;
}

@media (min-width: 1000px) {
    .Breadcrumb {
        width: 1000px;
        margin: 5px auto;
        padding-left: 10px;
    }
}

.FlexRowAroundCenter {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.FlexRowAroundStart {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
}

.FlexRowCenterCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.FlexRowBetweenCenter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.FlexRowEven {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.FlexRowStart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.FlexRowEnd {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.FlexRowCenter {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FlexColStartCenter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.FlexColStartStart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}