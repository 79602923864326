.showNavbar {
    background-color: var(--wnw2Primary);
}

.hideNavbar {
    display: none;
}

.Navbar {
    display: none;
}

.NavbarMobile {
    height: 45px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .Navbar {
        height: 45px;
        max-width: 1000px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
        z-index: 90;    
    }

    .NavbarMobile {
        display: none;
    }

    .Menu {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        overflow: hidden;
    }

    .MenuItem {
        margin: 0 15px;
        display: flex;
        height: 100%;
        width: 80px;
        align-items: center;
        color: #FFFFFF;
    }

    .MenuItem:hover {
        cursor: pointer;
    }

    .MenuItem:hover > .Submenu {
        display: block;
    }

    .Submenu {
        margin: 0;
        padding: 0;
        list-style: none;
        display: none;
        position: absolute;
        top: 45px;
        width: auto;
        background-color: #FFFFFF;
        border: 1px solid var(--wnwLightBlue);
        box-shadow: 0 2px 3px #ccc;
        border-radius: 3px;
        overflow: hidden;
        z-index: 50;
    }

    .Submenu a {
        text-decoration: none;
        color: var(--wnw2Primary);
    }

    .SubmenuItem {
        padding: 5px 8px;
        display: flex;
        height: 100%;
        width: 150px;
        align-items: center;
        color: var(--wnw2Primary);
    }

    .SubmenuItem:hover {
        background-color: #EEEEEE;
    }

    .MenuItemRight {
        margin: 0 15px;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        align-items: center;
        color: #FFFFFF;
    }

    .MenuItemRight:hover {
        cursor: pointer;
    }

    .MenuItemRight:hover > .Submenu {
        display: block;
    }

    .SubmenuRight a {
        text-decoration: none;
        color: var(--wnw2Primary);
    }

    .SubmenuItemRight {
        padding: 5px 8px;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        width: 100px;
        align-items: center;
        color: var(--wnw2Primary);
    }

    .SubmenuItemRight:hover {
        background-color: #EEEEEE;
    }
}